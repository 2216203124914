export function login(username, password) {
  return dispatch => {
    dispatch(setLoginSuccess(false));
    dispatch(setLoginError(null));

    callLoginApi(username, password, (error, userError, passError) => {
      if (!error) {
        dispatch(setLoginSuccess(true));
      } else {
        dispatch(setLoginError(error, userError, passError));
      }
    });
  }
}

function setLoginSuccess(isLoginSuccess) {
  return {
    type: 'SET_LOGIN_SUCCESS',
    isLoginSuccess,
    isLogout: false
  };
}

function setLoginError(loginError, userError, passError) {
  return {
    type: 'SET_LOGIN_ERROR',
    loginError,
    userError,
    passError
  }
}

function callLoginApi(username, password, callback) {
  let user = {username, password};

  if (username === 'admin' && password === 'admin') {
    localStorage.setItem('user', JSON.stringify(user));
    return callback(null);
  } else {
    return callback(new Error('Invalid username and password'), true, true);
  }
}

export function logout(history) {
  return {
    type: 'SET_LOGOUT',
    isLogout: true
  }
}
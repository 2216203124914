import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import login from '../reducer/login.reducer';
import app from '../reducer/app.reducer';

let store = '';

const rootReducer = combineReducers({
  login,
  app
});

if (process.env.NODE_ENV === 'development') {
	store = createStore(rootReducer, {}, applyMiddleware(thunk, logger));
} else {
	store = createStore(rootReducer, {}, applyMiddleware(thunk));
}

export default store;
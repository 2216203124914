import React from "react";
import PropTypes from "prop-types";
import config from '../../config/config';

const images = config.images.URL;

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;
    const total = this.props.pageSize * this.props.pages;
    const range1 = this.props.pageSize * this.props.page + 1;
    const range2 = this.props.pageSize + range1 - 1;
    let filtered = this.props.filtered[0];

    if(filtered) filtered = filtered.value.length
    else filtered = 0;

    return (
      <>
      <div className="Table__items row middle-xs" style={{display: filtered > 0 ? 'none' : 'flex'}}>
      {range1}-{range2} of {total} items
      </div>
      <div className="Table__pagination row end-xs middle-xs" style={{display: this.props.pages === 0 ? 'none' : 'flex', width: this.props.pages !== 0 ? '100%' : '60%'}}>
        <div className={`Table__prevPageWrapper ${activePage !== 1 ? 'pointer' : undefined}`}
          onClick={() => {
            if (activePage === 1) return;
            this.changePage(activePage - 1);
          }}>
          <img src={`${window.location.origin}${images}/Arrow@2x.svg`} alt="Prev" className={activePage === 1 ? 'arrow-disabled' : undefined} />
        </div>
        <div className="Table__visiblePagesWrapper">
          {visiblePages.map((page, index, array) => {
            return (
              <span
                key={page}
                className={
                  activePage === page
                    ? "Table__pageButton Table__pageButton--active"
                    : "Table__pageButton pointer"
                }
                onClick={this.changePage.bind(null, page)}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </span>
            );
          })}
        </div>
        <div className={`Table__nextPageWrapper ${activePage !== this.props.pages ? 'pointer' : undefined}`}
          onClick={() => {
            if (activePage === this.props.pages) return;
            this.changePage(activePage + 1);
          }}>
          <img src={`${window.location.origin}${images}/Arrow@2x.svg`} alt="Prev" className={activePage === this.props.pages ? 'arrow-disabled' : undefined} />
        </div>
      </div>
      </>
    );
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import dayjs from 'dayjs';
import * as HeaderActions from '../../redux/actions/app.actions';
import config from '../../config/config';
import './Storage.css';

const images = config.images.URL;
let storageArr = [];
let storageOpenedArr = [];
let c = 0;

class Storage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      storage: this.props.storage,
      storage_opened: this.props.storage,
      prevStorage: this.props.storage,
      breadCrumb: ['Storage Center'],
      showOpen: false,
      file: null,
      filtered: [],
      order: {
        name: {
          orderASC: false,
          isSelected: false
        },
        owner: {
          orderASC: false,
          isSelected: false
        },
        last_updated: {
          orderASC: true,
          isSelected: false
        },
        size: {
          orderASC: false,
          isSelected: false
        },
      },
      trSelected: -1
    }

    this.input = [];
    this.name = [];
  }

  selected(index, id) {
    this.setState({trSelected: index, fileId: id});
  }

  handleClick() {
    this.refs.fileUploader.click();
  }
 
  onChangeFile(e) {
    e.stopPropagation();
    e.preventDefault();

    let input = this.refs.fileUploader.value;

    if(input.length > 0) {
      let file = e.target.files[0];
      const fileName = file.name.split('.').slice(0, -1).join('.'); 
      const last_updated = dayjs(file.lastModifiedDate).format('MMM DD[,] hh[:]mma');
      let size = file.size / (1024 * 1024);
      size = size.toFixed(0)+'mb';

      file = {name: fileName, content_type: 'Doc', last_updated: last_updated, size: size}
      this.setState(prevState => ({
        storage_opened: [...prevState.storage_opened, file]
      }))
    }

    this.refs.fileUploader.value = '';
  }

  openFolder(item) {
    let search = document.getElementsByClassName('storage__search')[0];
    if(search) search.value = '';

    let id = item.id;
    storageArr = storageOpenedArr.filter(tab => tab.id === id);

    if(storageArr[0].content_type === 'Folder') {
      this.props.open_folder_storage(storageArr[0].content);
      this.setState({filtered: [], prevStorage: storageArr[0], storage_opened: storageArr[0].content})
      this.breadCrumb(storageArr[0].name);
      setTimeout(() => { this.selected(-1); })
    } else {
      this.setState({showOpen: true, file: item});
    }
  }

  breadCrumb(folder) {
    if(folder !== 'Storage Center') {
      this.setState(prevState => ({
        breadCrumb: [...prevState.breadCrumb, folder]
      }));
    } else {
      this.setState({breadCrumb: [folder]});
    }
  }

  filterAll(e) {
    const { value } = e.target;
    const filtered = this.state.storage_opened.filter(obj => obj.name.toLowerCase().includes(value.toLowerCase()));
    this.setState({ filtered });
  }

  orderBy(type) {
    const orderASC = this.state.order[type].orderASC;
    console.log(this.state.order[type].orderASC)

    storageOpenedArr.sort((a, b) => {
      if(orderASC) {
        if (a[type] < b[type]) {
          return 1;
        }
        if (a[type] > b[type]) {
          return -1;
        }
      } else {
        if (a[type] > b[type]) {
          return 1;
        }
        if (a[type] < b[type]) {
          return -1;
        }
      }      
      return 0;
    });

    // this.props.orderBy(storageOpenedArr);
   
    this.setState(prevState => {
      const newItems = {...prevState.order};
      for(var key in newItems) newItems[key].isSelected = false;
      newItems[type].orderASC = !newItems[type].orderASC;
      newItems[type].isSelected = true;
      return {order: newItems};
    });

    this.props.open_folder_storage(storageOpenedArr);
    c++;
  }

  rename(index, type) {
    if(type === 'change') {
      this.name[index].style.display = 'none';
      this.input[index].style.display = 'block';
      this.input[index].focus();
    } else {
      this.name[index].style.display = 'block';
      this.input[index].style.display = 'none';  
    }
  }

  // handleNewFolder = (e) => {
  //   const { value } = e.target;

  //   this.setState({ newFolder: value });
  // }

  addNewFolder = () => {
    // let folder = this.state.newFolder;
    let folder = {name: 'Untitled', content_type: 'Folder', content: ['']};

    this.props.addNewFolder(folder);
    this.rename(0, 'change');
  }
 
  render() {
    let { save, openStorage, storage, storage_opened, tabs, openFile, toggleStorage, toggleModal, openSaveFile, renameFile, saveFile, fileToSave, saveAs } = this.props;
    let { breadCrumb, filtered, file } = this.state;
    let filePath = breadCrumb.join('/');
    storageArr = [];
    storageOpenedArr = [];

    let mapStorage = storage;
    console.log(storage_opened)
    if(storage_opened.length > 0) mapStorage = storage_opened;
    if(filtered.length > 0) mapStorage = filtered;

    if(mapStorage[0] === '') mapStorage = [];

    return (
      // open &&
        // <div id="storage" className="h100 storage--opened">
        <div id="storage" className={`h100 ${openStorage && 'storage--opened'}`}>
          <div className="fade h100" onClick={() => { toggleStorage(); openSaveFile(false, []); this.breadCrumb('Storage Center') }}></div>
          <div className="storage__content row h100" onClick={(e) => { e.stopPropagation(); this.selected(-1); }}>
            <div className="col-xs-12">
              <img src={`${images}/Arrow-white@2x.svg`} className="storage__close__icon pointer" alt="Close Storage Center" onClick={() => { toggleStorage(); openSaveFile(false, []); this.breadCrumb('Storage Center') }} style={{ display: save === false ? 'block' : 'none' }} />
              {
                save &&
                  <div className="row">
                    <p className="storage__save__text">Save file to...</p>
                  </div>
              }
              <div className="storage__header row middle-xs between-xs">
                <div className="flex middle-xs">
                  {
                    breadCrumb.length < 3 ?
                      breadCrumb.map((entry, index) => (
                        <div key={index} className="storage__breadcrumb flex middle-xs">
                          <p className="storage__title pointer">{entry}</p>
                          <img src={`${images}/Dropdown.svg`} className="storage__breadcrumb__icon" alt="Breadcrumb" />
                        </div>
                      ))
                    :  
                      <div className="flex middle-xs">
                        <div className="storage__folders pointer">
                          <UncontrolledDropdown>
                            <DropdownToggle className="pointer middle-xs center-xs flex storage__folders__toggle" tag="div">
                              <img src={`${images}/Chevron@2x.svg`} alt="Chevron" />
                              <img src={`${images}/Folder.svg`} className="storage__folders__icon" alt="Folder" width="20" height="20" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu--noPadding dropdown-menu--table dropdown-menu--breadcrumb">
                              {
                                breadCrumb.map((entry, index) => (
                                  <DropdownItem key={index} className="dropdown__item dropdown__item--margin dropdown__item--breadcrumb pointer" tag="div">
                                    {entry}
                                  </DropdownItem>
                                ))
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="storage__breadcrumb flex middle-xs">
                          <img src={`${images}/Dropdown.svg`} className="storage__breadcrumb__icon" alt="Breadcrumb" style={{display: 'flex'}} />
                          <p className="storage__title pointer">{breadCrumb.slice(-1)[0]}</p>
                        </div>
                      </div>
                  }
                </div>
                {
                  !save &&
                    <div className="storage__header__right row middle-xs">
                      <div className="storage__header__right__items flex middle-xs pointer" onClick={this.addNewFolder}>
                        <img src={`${images}/Plus-white.svg`} className="storage__header__icon" alt="New Folder" />
                        <p>New Folder</p>
                      </div>
                      <div className="storage__header__right__items flex middle-xs pointer">
                        <img src={`${images}/Search@2x.svg`} className="storage__header__icon" alt="Search" />
                        <input type="text" placeholder="Search" className="storage__search" onChange={this.filterAll.bind(this)} />
                      </div>
                      <div className="storage__header__right__items flex middle-xs pointer" onClick={this.handleClick.bind(this)}>
                        <img src={`${images}/Upload-white@2x.svg`} className="storage__header__icon" alt="Upload File" />
                        <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={this.onChangeFile.bind(this)} />
                        <p>Upload File</p>
                      </div>
                    </div>
                }
              </div>
              <div className="storage__table--container">
                <table className="storage__table">
                  <thead>
                    <tr className="pointer">
                      <td width="33%" onClick={() => this.orderBy('name')} className={this.state.order['name'].isSelected ? 'storage__table__selected' : ''}>
                        <span>NAME</span>
                        <img src={`${images}/Chevron@2x.svg`} className={`none ${c % 2 === 0 && 'orderASC'}`} alt="Order" width="6" />
                      </td>
                      <td width="25%" onClick={() => this.orderBy('owner')} className={this.state.order['owner'].isSelected ? 'storage__table__selected' : ''}>
                        <span>OWNER</span>
                        <img src={`${images}/Chevron@2x.svg`} className={`none ${c % 2 === 0 && 'orderASC'}`} alt="Order" width="6" />
                      </td>
                      <td width="15%" onClick={() => this.orderBy('last_updated')} className={this.state.order['last_updated'].isSelected ? 'storage__table__selected' : ''}>
                        <span>LAST UPDATED</span>
                        <img src={`${images}/Chevron@2x.svg`} className={`none ${c % 2 === 0 && 'orderASC'}`} alt="Order" width="6" />
                      </td>
                      <td width="20%" align="center" onClick={() => this.orderBy('size')} className={this.state.order['size'].isSelected ? 'storage__table__selected' : ''}>
                        <span>SIZE</span>
                        <img src={`${images}/Chevron@2x.svg`} className={`none ${c % 2 === 0 && 'orderASC'}`} alt="Order" width="6" />
                      </td>
                      <td align="center">OPTIONS</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      mapStorage.map((entry, index) => {
                        storageArr.push(entry);
                        storageOpenedArr.push(entry);
                        return (
                          <tr className={`pointer ${this.state.trSelected === index && 'tr--selected'}`} key={index} title={filePath}
                            onClick={(e) => {e.stopPropagation(); this.openFolder(entry); this.selected(index, entry.id)}}
                            onDoubleClick={() => {
                              openFile(entry.id, tabs.length, {id: entry.id, index: '', name: entry.name, type: entry.type, content: entry.content, contentSaved: entry.contentSaved, version: entry.version});
                              toggleStorage();
                            }}
                          >
                            <td className="flex middle-xs">
                              <img src={`${images}/${entry.content_type}.svg`} className="storage__header__icon" alt="Folder" width="14" />
                              <span className="storage__name" ref={(ref) => this.name[index] = ref}>{entry.name}</span>
                              <input type="text" value={entry.name} className="storage__rename" style={{ display: 'none' }}
                                ref={(ref) => this.input[index] = ref} 
                                onClick={(e) => {e.stopPropagation()}} 
                                onChange={(el) => renameFile(entry.id, el.target.value)}
                                onBlur={() => this.rename(index, 'blur')}
                              />
                            </td>
                            <td>{entry.owner}</td>
                            <td>{dayjs(entry.last_updated).format('MMM DD[,] hh[:]mma')}</td>
                            <td align="center">{entry.size}</td>
                            <td align="center" onClick={(e) => { e.stopPropagation()}}>
                              <UncontrolledDropdown>
                                <DropdownToggle className="pointer middle-xs center-xs flex storage__table__options" tag="div">
                                  <img src={`${images}/Dots.svg`} alt="Option" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu--noPadding dropdown-menu--table" right>
                                  {
                                    entry.content_type === 'Doc' &&
                                      <>
                                        <DropdownItem className="dropdown__item dropdown__item--margin pointer" tag="div"
                                          onClick={() => {
                                            openFile(entry.id, tabs.length, {id: entry.id, index: '', name: entry.name, type: entry.type, content: entry.content, contentSaved: entry.contentSaved, version: entry.version});
                                            toggleStorage();
                                          }}
                                        >
                                          Start Session
                                        </DropdownItem>
                                        <DropdownItem className="dropdown__item dropdown__item--margin pointer" tag="div" onClick={() => { openSaveFile(true, storage); saveAs(entry); this.breadCrumb('Storage Center') }}>
                                          Save As
                                        </DropdownItem>
                                        <DropdownItem className="dropdown__item dropdown__item--margin pointer" tag="div">
                                          Download
                                        </DropdownItem>
                                        <DropdownItem className="dropdown__item dropdown__item--margin pointer" tag="div">
                                          Compare
                                        </DropdownItem>
                                      </>
                                  }
                                  <DropdownItem className="dropdown__item dropdown__item--margin pointer" tag="div" onClick={() => this.rename(index, 'change')} >
                                    Rename
                                  </DropdownItem>
                                  <DropdownItem className="dropdown__item dropdown__item--margin pointer" tag="div" onClick={() => toggleModal(true, 'deleteFile', entry.id)}>
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {
              !save && file && this.state.trSelected !== -1 &&
                <div className="storage__save__buttons row col-xs-12">
                  <button className="btn"
                    onClick={() => {
                      openFile(this.state.fileId, tabs.length, {id: file.id, index: '', name: file.name, type: file.type, content: file.content, contentSaved: file.contentSaved, version: file.version});
                      toggleStorage();
                    }}>Open</button>
                </div>
            }
            {
              save &&
                <div className="storage__save__buttons row col-xs-12">
                  <button className="btn" onClick={() => { saveFile(true, this.state.prevStorage, fileToSave); toggleStorage(); openSaveFile(false, []); this.breadCrumb('Storage Center') }}>Save File</button>
                  <button className="btn btn--gray" onClick={() => { toggleStorage(); openSaveFile(false, []); this.breadCrumb('Storage Center') }}>Cancel</button>
                </div>
            }
          </div>
          {/* <Modal open={this.state.modal.open} type={this.state.modal.type} newFolder={() => this.newFolder()} handleNewFolder={this.handleNewFolder} addNewFolder={() => this.addNewFolder()} /> */}
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeFile: state.app.activeFile,
    openStorage: state.app.openStorage,
    save: state.app.save,
    tabs: state.app.tabs,
    storage: state.app.storage,
    storage_opened: state.app.storage_opened,
    fileToSave: state.app.fileToSave
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(HeaderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
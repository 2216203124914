const INITIAL_STATE = {
  save: false,
  isSessionSaved: false,
  savedError: null,
  sessionType: '',
  activeFolder: {},
  activeFile: -1,
  message: '',
  compareVersion: false,
  openStorage: false,
  openModal: false,
  modalType: '',
  modalId: -1,
  fileName: '',
  fileToSave: [],
  sessions: [],
  libraries: [
    {
      folder: 'Active Database 1',
      files: [
        {
          id: 111,
          name: 'File 1',
          type:'python',
          version: '1.0.0',
          isGraph: true,
          content: ''
        },
        {
          id: 222,
          name: 'File 2',
          type:'eql',
          version: '1.0.0',
          isGraph: false,
          content: ''
        }
      ]
    },
    {
      folder: 'Active Database 2',
      files: [
        {
          id: 333,
          name: 'File 3',
          type:'python',
          version: '1.0.0',
          isGraph: false,
          content: ''
        },
        {
          id: 444,
          name: 'File 4',
          type:'eql',
          version: '1.0.0',
          isGraph: true,
          content: ''
        }
      ]
    },
    {
      folder: 'Active Database 3',
      files: [
        {
          id: 555,
          name: 'File 5',
          type:'python',
          version: '1.0.0',
          isGraph: true,
          content: ''
        },
        {
          id: 666,
          name: 'File 6',
          type:'eql',
          version: '1.0.0',
          isGraph: false,
          content: ''
        }
      ]
    },
  ],
  storage: [
    {
      id: 1,
      level: 0,
      content_type: 'Folder',
      name: 'AWS S3',
      owner: 'Stanley Patrick',
      last_updated: '2018-12-29 22:30',
      size: '21mb',
      content: [
        {
          level: 1,
          id: 100,
          parent: 1,
          content_type: 'Folder',
          name: 'AWS S3 subfolder',
          owner: 'Stanley Patrick',
          last_updated: '2018-12-29 22:30',
          size: '21mb',
          content: [
            {
              id: 111,
              parent: 100,
              level: 2,
              content_type: 'Doc',
              name: 'File 1',
              owner: 'Maicon',
              type:'Python',
              version: '1.0.0',
              content: 'uhul 1',
              contentSaved: 'uhul 1',
            },
            {
              id: 222,
              parent: 100,
              level: 2,
              content_type: 'Doc',
              name: 'File 2',
              owner: 'Fabio',
              type:'EQL',
              version: '1.0.0',
              content: 'uhul 2',
              contentSaved: 'uhul 2',
            }
          ]
        },
        {
          id: 111,
          parent: 1,
          level: 1,
          content_type: 'Doc',
          name: 'File 1',
          owner: 'Maicon F',
          type:'Python',
          version: '1.0.0',
          content: 'aeee 1',
          contentSaved: 'aeee 1'
        },
        {
          id: 222,
          parent: 1,
          level: 1,
          content_type: 'Doc',
          name: 'File 2',
          owner: 'Fabio M',
          type: 'EQL',
          version: '1.0.0',
          content: 'aeeee 2',
          contentSaved: 'aeeee 2'
        }
      ],
    },
    {
      id: 2,
      content_type: 'Folder',
      name: 'File System',
      owner: 'Isaac Waters',
      last_updated: '2019-01-29 22:30',
      size: '21mb',
      content: [
        {
          id: 333,
          content_type: 'Doc',
          name: 'File 2',
          type:'Python',
          version: '1.0.0',
          content: 'ebaaaa 1',
          contentSaved: 'ebaaaa 1'
        },
        {
          id: 444,
          content_type: 'Doc',
          name: 'File 3',
          type:'EQL',
          version: '1.0.0',
          content: 'ebaaaa 2',
          contentSaved: 'ebaaaa 2'
        }
      ]
    },
    {
      id: 3,
      level: 0,
      content_type: 'Folder',
      name: 'HDFS',
      owner: 'Jeffrey Haynes',
      last_updated: '2019-04-29 22:30',
      size: '21mb',
      content: [
        {
          id: 333,
          level: 1,
          content_type: 'Doc',
          name: 'File 2',
          type:'Python',
          version: '1.0.0',
          content: 'brasil',
          contentSaved: 'brasil'
        },
        {
          id: 444,
          content_type: 'Doc',
          name: 'File 3',
          type:'EQL',
          version: '1.0.0',
          content: 'USA',
          contentSaved: 'USA'
        }
      ]
    },
  ],
  storage_opened: [
    {
      id: 1,
      level: 0,
      content_type: 'Folder',
      name: 'AWS S3',
      owner: 'Stanley Patrick',
      last_updated: '2018-12-29 22:30',
      size: '21mb',
      content: [
        {
          level: 1,
          id: 100,
          parent: 1,
          content_type: 'Folder',
          name: 'AWS S3 subfolder',
          owner: 'Stanley Patrick',
          last_updated: '2018-12-29 22:30',
          size: '21mb',
          content: [
            {
              id: 111,
              parent: 100,
              level: 2,
              content_type: 'Doc',
              name: 'File 1',
              owner: 'Maicon',
              type:'Python',
              version: '1.0.0',
              content: 'uhul 1',
              contentSaved: 'uhul 1',
            },
            {
              id: 222,
              parent: 100,
              level: 2,
              content_type: 'Doc',
              name: 'File 2',
              owner: 'Fabio',
              type:'EQL',
              version: '1.0.0',
              content: 'uhul 2',
              contentSaved: 'uhul 2',
            }
          ]
        },
        {
          id: 111,
          parent: 1,
          level: 1,
          content_type: 'Doc',
          name: 'File 1',
          owner: 'Maicon F',
          type:'Python',
          version: '1.0.0',
          content: 'aeee 1',
          contentSaved: 'aeee 1'
        },
        {
          id: 222,
          parent: 1,
          level: 1,
          content_type: 'Doc',
          name: 'File 2',
          owner: 'Fabio M',
          type: 'EQL',
          version: '1.0.0',
          content: 'aeeee 2',
          contentSaved: 'aeeee 2'
        }
      ],
    },
    {
      id: 2,
      content_type: 'Folder',
      name: 'File System',
      owner: 'Isaac Waters',
      last_updated: '2019-01-29 22:30',
      size: '21mb',
      content: [
        {
          id: 333,
          content_type: 'Doc',
          name: 'File 2',
          type:'Python',
          version: '1.0.0',
          content: 'ebaaaa 1',
          contentSaved: 'ebaaaa 1'
        },
        {
          id: 444,
          content_type: 'Doc',
          name: 'File 3',
          type:'EQL',
          version: '1.0.0',
          content: 'ebaaaa 2',
          contentSaved: 'ebaaaa 2'
        }
      ]
    },
    {
      id: 3,
      level: 0,
      content_type: 'Folder',
      name: 'HDFS',
      owner: 'Jeffrey Haynes',
      last_updated: '2019-04-10 22:30',
      size: '21mb',
      content: [
        {
          id: 333,
          level: 1,
          content_type: 'Doc',
          name: 'File 2',
          type:'Python',
          version: '1.0.0',
          content: 'brasil',
          contentSaved: 'brasil'
        },
        {
          id: 444,
          content_type: 'Doc',
          name: 'File 3',
          type:'EQL',
          version: '1.0.0',
          content: 'USA',
          contentSaved: 'USA'
        }
      ]
    },
  ],
  templates: [
    {
      python: [
        {
          name: 'Template_001_Python',
          content: 'aaaaaaa',
          contentSaved: 'aaaaaaa',
          version: '0.1.0',
          type: 'python',
          content_type: 'Doc'
        },
        {
          id: 2,
          name: 'Template_002_Python',
          content: 'bbbbbbb',
          contentSaved: 'bbbbbbb',
          version: '0.1.0',
          type: 'python',
          content_type: 'Doc'
        },
        {
          id: 3,
          name: 'Template_003_Python',
          content: 'ccccccc',
          contentSaved: 'ccccccc',
          version: '0.1.0',
          type: 'python',
          content_type: 'Doc'
        }
      ],
      eql: [
        {
          id: 4,
          name: 'Template_001_EQL',
          content: 'aaaaaaa',
          contentSaved: 'aaaaaaa',
          version: '0.1.0',
          type: 'eql',
          content_type: 'Doc'
        },
        {
          name: 'Template_002_EQL',
          content: 'bbbbbbb',
          contentSaved: 'bbbbbbb',
          version: '0.1.0',
          type: 'eql',
          content_type: 'Doc'
        },
        {
          id: 6,
          name: 'Template_003_EQL',
          content: 'ccccccc',
          contentSaved: 'ccccccc',
          version: '0.1.0',
          type: 'eql',
          content_type: 'Doc'
        }
      ],
      r: [
        {
          id: 7,
          name: 'Template_001_R',
          content: 'aaaaaaa',
          contentSaved: 'aaaaaaa',
          version: '0.1.0',
          type: 'r',
          content_type: 'Doc'
        },
        {
          id: 8,
          name: 'Template_002_R',
          content: 'bbbbbbb',
          contentSaved: 'bbbbbbb',
          version: '0.1.0',
          type: 'r',
          content_type: 'Doc'
        },
        {
          id: 9,
          name: 'Template_003_R',
          content: 'ccccccc',
          contentSaved: 'ccccccc',
          version: '0.3.0',
          type: 'r',
          content_type: 'Doc'
        }
      ],
      sas: [
        {
          id: 10,
          name: 'Template_001_SAS',
          content: 'aaaaaaa',
          contentSaved: 'aaaaaaa',
          version: '0.1.0',
          type: 'sas',
          content_type: 'Doc'
        },
        {
          id: 11,
          name: 'Template_002_SAS',
          content: 'bbbbbbb',
          contentSaved: 'bbbbbbb',
          version: '0.1.0',
          type: 'sas',
          content_type: 'Doc'
        },
        {
          id: 12,
          name: 'Template_003_SAS',
          content: 'ccccccc',
          contentSaved: 'ccccccc',
          version: '0.1.0',
          type: 'sas',
          content_type: 'Doc'
        }
      ]
    }
  ],
  tabs: [],
  saved: false,
  alerts: [
    {
      id: 1,
      alert: 'Couldn’t locate server 1'
    },
    {
      id: 2,
      alert: 'Couldn’t locate server 2'
    },
    {
      id: 3,
      alert: 'Couldn’t locate server 3'
    }
  ],
  console: {
    selected: 'Logs',
    logs: ["01-18 00:33:29.643 [connect] INFO  - Connection to 'test' established", "01-18 00:33:29.643 [test] INFO  - Connection to 'test' established", "01-18 00:33:29.643 [save] INFO  - Connection to 'test' established"],
    output: ["output"],
    interactive: ["interactive"],
    variables: ["variables"],
    search: ["01-18 00:33:29.643 [connect] INFO  - Connection to 'test' established", "01-18 00:33:29.643 [test] INFO  - Connection to 'test' established", "01-18 00:33:29.643 [save] INFO  - Connection to 'test' established"],
  },
  graphs: [
    {
      id: 1,
      name: 'Output_002',
      graph: ''
    },
    {
      id: 2,
      name: 'Active_chart-03',
      graph: ''
    },
    {
      id: 3,
      name: 'Active_output-002',
      graph: ''
    }
  ],
  pip: null
}


export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_SESSION_SAVE_SUCCESS':
      return {
        ...state,
        isSessionSaved: action.isSessionSaved,
        message: action.message,
        tabs: state.tabs.map(tab => tab.index === action.id ? ({...tab, contentSaved: tab.content}) : tab)
      }

    case 'SET_SESSION_SAVE_ERROR':
      return {
        ...state,
        savedError: action.savedError,
        message: action.message
      };

    case 'SET_SESSION_TYPE':
      return {
        ...state,
        sessionType: action.sessionType,
        activeFile: action.activeFile
      }

    case 'CLOSE_SESSION':
      let tabs = state.tabs.splice(action.id, 1);
      tabs = state.tabs.filter((n) => { return n });
      let activeFile = tabs.length-1;
      // console.log('aF: '+activeFile);
      // activeFile = tabs[activeFile];

      // if(activeFile) activeFile = activeFile.id

      // console.log(activeFile)

      return {
        ...state,
        tabs: tabs,
        activeFile: activeFile
      }

    case 'SET_ALERTS':
      return {
        ...state,
        alerts: [
          ...state.alerts,
          action.alert
        ]
      }

    case 'CLEAR_ALL_ALERTS':
      return {
        ...state,
        alerts: []
      }

    case 'REMOVE_ALERT':
      let alerts = [...state.alerts.filter(item => item.id !== action.id)];

      return {
        ...state,
        alerts: alerts
      }

    case 'SET_EDITOR_CONTENT':
      return {
        ...state,
        tabs: state.tabs.map(tab => tab.index === action.id ? ({...tab, content: action.content}) : tab)
      }

    case 'SET_FILE_HEADER':
      var hasMatch = state.tabs.filter(tab => tab.id === action.id);

      if(hasMatch.length > 0) {
        return {
          ...state,
          sessionType: action.sessionType,
          tabs: [
            ...state.tabs,
          ],
          activeFile: hasMatch[0].index
        }
      } else {
        return {
          ...state,
          sessionType: action.sessionType,
          tabs: [
            ...state.tabs,
            action.file
          ],
          activeFile: action.activeFile,
        }
      }

    case 'SELECT_CONSOLE':
      let search = action.consoleType;

      switch (search) {
        case 'Output':
          search = state.console.output;
          break;

        case 'Interactive':
          search = state.console.interactive;
          break;

        case 'Variables':
          search = state.console.variables;
          break;

        default:
          search = state.console.logs;
      }

      return {
        ...state,
        console: {
          ...state.console,
          selected: action.consoleType,
          search: search
        }
      }

    case 'SEARCH_CONSOLE':
      return {
        ...state,
        console: {
          ...state.console,
          search: state.console.search.filter(s => s.toLowerCase().includes(action.search.toLowerCase()))
        }        
      }

    case 'SEARCH_LIBRARY':
      return {
        ...state,
        searchLibraries: state.searchLibraries.filter(s => s.toLowerCase().includes(action.search.toLowerCase()))
      }

    // case 'OPEN_SESSION':
    //   return {
    //     ...state,
    //     templates: {
    //       ...state.templates,
    //       [action.sessionType]: {
    //         ...state.templates
    //       }
    //     }        
    //   }

    case 'SET_PIP':
      let pip = null;
      if(action.graph) {
        pip = [{name: action.graph.name, graph: action.data}];
      }

      return {
        ...state,
        pip: pip
        // pip: action.graph, action.data
      }

    case 'SORT_STORAGE':
      return {
        ...state,
        storage_opened: action.storage_opened
      }

    case 'OPEN_STORAGE':
      return {
        ...state,
        storage_opened: action.storage_opened
      }

    case 'OPEN_STORAGE_TO_SAVE':
    // let level = action.level;
    // console.log(level);
    // if(!level) level = 0; 

    // console.log(state.storage[level]);
    // let filter = state.storage.filter((entry, index) => {
    //   return findFirst(entry, '', { id: action.id })
    // })
    // // let filter = findFirst(state.storage, '', { id: action.id } )
    // console.log(filter);
    return {
      ...state,
      save: action.save,
      storage_opened: state.storage
    }

    case 'FILE_TO_SAVE':
      return {
        ...state,
        fileToSave: action.file
      }

    case 'SAVE_FILE':
      // let level = action.level;
      // console.log(level);
      // if(!level) level = 0; 

      // console.log(state.storage[level]);
      // let filter = state.storage.filter((entry, index) => {
      //   return findFirst(entry, '', { id: action.id })
      // })
      // // let filter = findFirst(state.storage, '', { id: action.id } )
      // console.log(filter);

      let storage = action.storage;
      let result = '';

      console.log(storage)
      action.file.name = state.fileName;
      if(Array.isArray(storage)) {
        result = [...state.storage, action.file]
      } else {
        storage = storage.id;  
        result = state.storage.map(item => item.id === storage ? ({...item, content: [...item.content, action.file]}) : item);
      }
      // let result = state.storage.filter((item) => item.id === action.storage.id);

      console.log('result', result)

      return {
        ...state,
        save: action.save,
        storage: result,
        storage_opened: result,
        tabs: state.tabs.map((tab, index) => index === state.activeFile ? ({...tab, name: state.fileName}) : tab)
      }
      
    case 'SET_FILE_NAME':
      return {
        ...state,
        fileName: action.file
      }

    case 'SET_COMPARE_VERSION':
      return {
        ...state,
        compareVersion: action.compareVersion
      }
      
    case 'TOGGLE_STORAGE':
      return {
        ...state,
        openStorage: action.openStorage
      }
      
    case 'TOGGLE_MODAL':
      return {
        ...state,
        openModal: action.openModal,
        modalType: action.modalType,
        modalId: action.id
      }
      
    case 'SET_STORAGE_OPENED':
      return {
        ...state,
        storage_opened: [action.storage_opened, ...state.storage_opened]
      }
      
    case 'SET_NEW_FOLDER_NAME':
      return {
        ...state,
        storage_opened: state.storage_opened.map(item => item.id === action.storage_opened_id ? ({...item, name: action.storage_opened_val}) : item)
      }
      
    case 'DELETE_FILE':
      return {
        ...state,
        storage_opened: state.storage_opened.filter(item => item.id !== action.storage_opened_id),
        modalId: action.id
      }

    default:
      return state;
  }
}
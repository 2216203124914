const INITIAL_STATE = {
  isLoginSuccess: false,
  isLogout: false,
  loginError: null,
  userError: null,
  passError: null
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_LOGIN_SUCCESS':
      return {
        ...state,
        isLoginSuccess: action.isLoginSuccess,
        isLogout: false
      };

    case 'SET_LOGIN_ERROR':
      return {
        ...state,
        loginError: action.loginError,
        userError: action.userError,
        passError: action.passError
      };

    case 'SET_LOGOUT':
      return {
        ...state,
        isLogout: action.isLogout
      }

    default:
      return state;
  }
}
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import App from '../App';
import Login from '../login/Login';

const url = process.env.PUBLIC_URL;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('user')
      ? <Component {...props} />
      : <Redirect to={{ pathname: url+'/login', state: { from: props.location } }} />
  )} />
);

class Teste extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={`${url}/login`} component={Login} />
          <PrivateRoute exact path={url} component={App} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Teste;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../redux/actions/login.actions';
import { Redirect } from 'react-router-dom';
import config from '../../config/config';
import './Login.css';

const url = process.env.PUBLIC_URL;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    let { username, password } = this.state;
    this.props.login(username, password);
  }

  render() {
    let {username, password} = this.state;
    let {isLoginSuccess, loginError, userError, passError} = this.props;
    let images = config.images.URL;
    return (
      <div className="container h100">
        <div className="row center-xs h100">
          <div className="col-xs-10 center-xs h100">
            <div className="row center-xs h100 middle-xs">
              <form className="login col-xs-12" name="loginForm" onSubmit={this.onSubmit.bind(this)}>
                <img src={`${images}/Logo.svg`} alt="ahub IAS" width="52" height="50" />
                <h1 className="login__title">Login to IAS</h1>
                <div>
                  { isLoginSuccess && <Redirect to={{ pathname: url+'/' }} /> }
                  { loginError && <p className="login__error">{loginError.message}</p> }
                </div>
                <div className={`start-xs ${userError && 'login__error--input'}`}>
                  <p className="login__label">USERNAME:</p>
                  <input type="text" className="login__input" name="username" placeholder="Enter your username" onChange={e => this.setState({username: e.target.value})} value={username} required/>
                </div>
                <div className={`start-xs ${passError && 'login__error--input'}`}>
                  <p className="login__label">PASSWORD:</p>
                  <input type="password" className="login__input" name="password" placeholder="Enter your password" onChange={e => this.setState({password: e.target.value})} value={password} required/>
                </div>
                <input type="submit" value="Login" className="btn btn--login" />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onSubmit = (e) => {
    e.preventDefault();
    let { username, password } = this.state;
    this.props.login(username, password);
  }
}

const mapStateToProps = (state) => {
  return {
    isLoginSuccess: state.login.isLoginSuccess,
    loginError: state.login.loginError,
    userError: state.login.userError,
    passError: state.login.passError
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(login(username, password))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
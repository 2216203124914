import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from 'react-switch';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import AutosizeInput from 'react-input-autosize';
import * as HeaderActions from '../../redux/actions/app.actions';
import config from '../../config/config';
import './SessionHeader.css';

let images = config.images.URL;

class SessionHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  handleSwitch() {
    this.setState({ checked: !this.state.checked });
    this.props.compare(!this.state.checked);
  }

  updateInputValue = (event, selected) => {
    let val = event.target.value;
    this.setState({inputValue: val});
  }

  render() {
    let { isSessionSaved, message, alerts, tabs, activeFile, toggleModal, removeAllNotification, removeNotification, runCode, saveAs } = this.props;
    let number = alerts.length;
    // let file = tabs.filter(tab => tab.id === activeFile);
    let file = '';
    if(tabs) {
      file = tabs.slice(-1)[0];
    }
    // console.log(file)
    let inputValue = this.state.inputValue;

    if(activeFile > -1) {
      inputValue = file.version;
      file = file.name;
    }

    return (
      <div className="sHeader">
        <div className="row h100 middle-xs col-xs-12">
          {
            tabs.length > 0 &&
            <>
              <div className="col-xs-2 row h100">
                <div className="sHeader__btn middle-xs center-xs flex pointer" onClick={() => runCode()}>
                  <p>Run</p>
                  <img src={`${images}/Run.svg`} alt="Run" className="sHeader__icon" />
                </div>
                <UncontrolledDropdown>
                  <DropdownToggle className="sHeader__btn pointer middle-xs center-xs flex">
                    Save
                    <img src={`${images}/Dropdown-orange.svg`} alt="Dropdown" className="sHeader__icon" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu--noPadding">
                    <DropdownItem className="dropdown__item dropdown__item--margin" toggle={true} tag="div">
                      <div className="pointer">Download</div>
                    </DropdownItem>
                    <DropdownItem toggle={true} tag="div">
                      {/* <div className="dropdown__item pointer" onClick={() => saveSession(activeFile)}>Save As</div> */}
                      <div className="dropdown__item pointer" onClick={() => { toggleModal(true, 'saveFile', tabs[activeFile]); saveAs(tabs[activeFile]) }}>Save As</div>
                    </DropdownItem>
                    <UncontrolledDropdown className="dropdown-item">
                      <DropdownToggle className="dropdown__item dropdown__item--margin pointer" tag="div">
                        Commit
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-commit" tag="span">
                        <p className="dropdown-commit__title">GIT COMMIT</p>
                        <textarea className="dropdown-commit__textarea" placeholder="Enter update title"></textarea>
                        <div className="row">
                          <DropdownItem toggle={true} tag="div" className="col-xs flex">
                            <input type="submit" value="Commit" className="btn btn--space col-xs-12" />
                          </DropdownItem>
                          <DropdownItem toggle={true} tag="div" className="col-xs flex">
                            <input type="button" value="Cancel" className="btn btn--white col-xs-12" />
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="col-xs-7 row h100 middle-xs">
                <p className="sHeader__text">Sample left outer join</p>
                <div className="sHeader__version flex middle-xs">
                  <p className="sHeader__text sHeader__text--gray">VERSION</p>
                  <AutosizeInput type="text" className="sHeader__text sHeader__text--input" value={inputValue}
                    onChange={(e) => {
                      this.updateInputValue(e);
                    }}
                  />
                </div>
                <p className="sHeader__text sHeader__text--gray">STORAGE</p>
                <p className="sHeader__text">Test Cases - EQL</p>
                <div className="flex middle-xs" onClick={this.handleSwitch.bind(this)}>
                  <Switch checked={this.state.checked} onChange={this.handleSwitch.bind(this)} onColor="#EA7E00" onHandleColor="#fff" handleDiameter={11} uncheckedIcon={false} checkedIcon={false} height={7} width={18} className="sHeader__switch" />
                  <p className="sHeader__text">Compare Versions</p>
                </div>
              </div>
            </>
          }
          <div className={`${tabs.length > 0 ? 'col-xs-3' : 'col-xs-12'} row h100 middle-xs end-xs`}>
            <div className="sHeader__alert">
              {/* DEFAULT STYLE MESSAGE */}
              {/*isSessionSaved && <p className="sHeader__alert--default">{message}</p>*/}

              {/* ERROR STYLE MESSAGE */}
              {/*isSessionSaved && <p className="sHeader__alert--error">{message}</p>*/}

              {/* SAVED STYLE MESSAGE */}
              {isSessionSaved &&
                <div className="row middle-xs">
                  <img src={`${images}/Check@2x.svg`} alt="Saved" />
                  <p className="sHeader__alert--saved">{message}</p>
                </div>
              }
            </div>
            <div className="sHeader__notification pointer">
              <UncontrolledDropdown>
                <DropdownToggle tag="div" className="sHeader__notification__icon__hover flex">
                  <img src={`${images}/Notification_Bell.svg`} width="14" height="16" alt="Alerts" className="sHeader__notification__icon pointer" />
                  {
                    (number > 0) && <div className="sHeader__notification__dot flex center-xs middle-xs pointer">{number}</div>
                  }                  
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu--noPadding dropdown-menu--alerts start-sm " right>
                  <div className="flex between-xs">
                    <div className="dropdown__item dropdown__item--alerts">ALERTS</div>
                    {
                      (number > 0) && <div className="dropdown__item dropdown__item--clear pointer" onClick={() => removeAllNotification()}>Clear All</div>
                    }
                  </div>
                  <div className="alerts__container row">
                    {
                      alerts.map((entry, index) => (
                        <div key={index} className="dropdown__item dropdown__item--alert between-xs middle-xs flex">
                          <span className="col-xs-11">{entry.alert}</span>
                          <img src={`${images}/Close-gray@2x.svg`} width="10" height="10" alt="Clear" className="sHeader__notification__remove pointer" onClick={() => removeNotification(entry.id)} />
                        </div>
                      ))
                    }
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let header = state.app;
  return {
    isSessionSaved: header.isSessionSaved,
    message: header.message,
    alerts: header.alerts,
    tabs: header.tabs,
    activeFile: header.activeFile,
    fileToSave: header.fileToSave
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(HeaderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SessionHeader);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HeaderActions from '../../redux/actions/app.actions';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineSeries } from 'react-vis';
import SlideToggle from 'react-slide-toggle';
import config from '../../config/config';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { Rnd } from 'react-rnd';
// import Draggable from 'react-draggable';
import PopoutWindow from 'react-popout';
import AutoSizer from 'react-virtualized-auto-sizer';
import LibraryPanel from '../modal/LibraryPanel';
import './Sidebar.css';

let images = config.images.URL;
let isIE = /*@cc_on!@*/false || !!document.documentMode;
let isEdge = !isIE && !!window.StyleMedia;

const Graph = (graph) => (
  <AutoSizer>
    {({ width, height }) => (
      <div className="plot">
        <XYPlot
          height={height}
          width={width}
          colorType="linear" colorDomain={[0, 9]} colorRange={['yellow', 'orange']}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis />
          <YAxis />
          {
            graph.graphs.map((props, index) => {
              return (
                <LineSeries {...props} key={index} />
              )
            })
          }
        </XYPlot>
      </div>
    )}
  </AutoSizer>
);

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (styleSheet.cssRules) { // for <style> elements
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expand: false,
      library: false,
      inputValue: '',
      librariesHeight: 'auto',
      graphHeight: 'auto',
			pip: {
        y: 0,
        x: -18,
				width: 300,
				height: 287
			}
    }
  }

//   componentDidMount() {
//     let sidebar = document.getElementById('sidebar').clientHeight;
//     let divGraphs = document.getElementById('sidebar__graphs').clientHeight;
//     let divLibs = document.getElementById('sidebar__library').clientHeight;
//     let graphs = this.props.graphs;
//     let libs = this.props.libraries;
//     let lHeight = 'auto';
//     let gHeight = 'auto';

//     if(!graphs) graphs = [];
//     if(!libs) libs = [];
// alert(divLibs)
// alert(divGraphs)
//     if(graphs.length > 0) {
//       if(divLibs + divGraphs > sidebar) {
//         if(divGraphs > sidebar/2) {
//           if(divLibs + 44 > sidebar/2) {
//             lHeight = '49%';

//             if(divGraphs > divLibs) {
//               gHeight = '45%';
//             }
//           } else {
//             alert('ee')
//           }
//         } else {
//           alert('eeeeee')
//         }
//       }
//     }

//     if(libs.length > 0) {
//       gHeight = '45%';
//     }


//     alert(lHeight)
//     this.setState({
//       librariesHeight: lHeight,
//       graphHeight: gHeight
//     });
//   }

  expandGraph = () => {
    this.setState({expand: true})
  }

  openLibrary = (output) => {
    console.log(output)
    this.setState({library: true, data: output})
  }

  openPip = (graph) => {
    let wHeigth = window.innerHeight;   
    let sideOffset = document.getElementById('sidebar__graphs').offsetTop;

    let calc = wHeigth - sideOffset - 293;

    this.setState(prevState => ({
      pip: {
        ...prevState.pip,
        y: calc
      }
    }));

    this.props.openGraph(graph);
  }

  closeGraph = () => {
    this.setState(prevState => ({
      pip: {
        ...prevState.pip,
				width: 300,
				height: 287
      }
    }));

    this.props.closePip();
  }

  updateInputValue = (event, selected) => {
		let val = event.target.value;
		this.setState({inputValue: val});
    // this.props.searchLibrary(val)
		// if(val.length > 0) {
		// 	this.props.searchLibrary(val)
		// } else {
		// 	this.props.showLibraries(this.props.consoleSelected)
		// }
  }
  
  openSearch = () => {
    this.refs.search.focus();
  }

  render() {
    let { tabs, libraries, graphs, pip, openFile } = this.props;
    let { inputValue } = this.state;

    return (
      <aside id="sidebar" className="col-xs-3">
        <SplitterLayout vertical={true}>
          <div className="sidebar__content" id="sidebar__library">
            <div className="sidebar__header row col-xs-12 between-xs middle-xs">
              <p className="sidebar__title">library panel</p>
              <div className={`sidebar__library__searchContent flex middlex-xs  ${inputValue !== '' && 'sidebar__library__searchContent--active'}`} onClick={this.openSearch}>
                <img src={`${images}/Search@2x.svg`} alt="Search" className="sidebar__library__icon pointer" width="12" height="12" />
                <input type="text" id="sidebar__library__search" ref="search" placeholder="Search" onChange={(e) => { this.updateInputValue(e) }} />
              </div>
            </div>
            <div className="sidebar__scroll">
              {
                libraries.map((entry, index) => (
                  <SlideToggle key={index} className="sidebar__item pointer" duration={400} collapsed render={({ onToggle, setCollapsibleElement, toggleState }) => (
                    <div className={"sidebar__item__content row middle-xs pointer "+ (toggleState || "").toLowerCase()}>
                      <div className="sidebar__item__hover sidebar__item__hover--margin flex middle-xs" title={entry.folder} onClick={onToggle}>
                        <img src={`${images}/Dropdown.svg`} alt={entry.folder} className="sidebar__item__arrow" />
                        <img src={`${images}/Folder.svg`} width="10" height="9" alt="Folder" className="sidebar__item__folder" />
                        <p className="sidebar__item__name">{entry.folder}</p>
                      </div>
                      <div className="sidebar__item__submenu row middle-xs col-xs-12" ref={setCollapsibleElement}>
                        {
                          entry.files.map((entry, fileIndex) => (
                            <div key={fileIndex} className="sidebar__item__files sidebar__item__hover flex middle-xs" title={entry.name} onDoubleClick={() => { (!isIE && !isEdge && entry.isGraph) ? this.openLibrary(entry) : openFile(entry.id, tabs.length, entry)}}>
                              {/* <img src={`${images}/Dropdown.svg`} alt={entry} className="sidebar__item__arrow" /> */}
                              <img src={`${images}/Doc.svg`} alt="File" width="10" height="12" className="sidebar__item__folder" />
                              <p className="sidebar__item__name">{entry.name}</p>
                              <UncontrolledDropdown onClick={(e) => e.stopPropagation()}>
                                <DropdownToggle tag="div" className="flex middle-xs" style={{'padding': 10}} onClick={(e) => { e.stopPropagation(); }}>
                                  <img src={`${images}/Dots.svg`} alt="Options" className="sidebar__item__dots" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu--noPadding" right>
                                  {(!isEdge && !isIE && entry.isGraph) &&
                                    <DropdownItem tag="div">
                                      <div className="dropdown__item dropdown__item--margin pointer" onClick={() => this.openLibrary(entry)}>Open as a tab</div>
                                    </DropdownItem>
                                  }
                                  {(!entry.isGraph) &&
                                    <DropdownItem tag="div">
                                      <div className="dropdown__item dropdown__item--margin pointer" onClick={() => { openFile(entry.id, tabs.length, entry)}}>Open</div>
                                    </DropdownItem>
                                  }
                                  <UncontrolledDropdown className="dropdown-item">
                                    <DropdownToggle className="dropdown__item dropdown__item--margin pointer" tag="div">
                                      Details
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-details" tag="span" right>
                                      <div className="dropdown-details__content">
                                        <p className="dropdown-details__text dropdown-details__text--title">Owner</p>
                                        <p className="dropdown-details__text">Josh Tillman</p>
                                      </div>
                                      <div className="dropdown-details__content">
                                        <p className="dropdown-details__text dropdown-details__text--title">Last Updated</p>
                                        <p className="dropdown-details__text">Dec 29, 2018</p>
                                      </div>
                                      <div className="dropdown-details__content">
                                        <p className="dropdown-details__text dropdown-details__text--title">Size</p>
                                        <p className="dropdown-details__text">21mb</p>
                                      </div>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                  <DropdownItem tag="div">
                                    <div className="dropdown__item dropdown__item--margin pointer">Download</div>
                                  </DropdownItem> 
                                  <DropdownItem tag="div">
                                    <div className="dropdown__item dropdown__item--margin pointer">Delete</div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    )}
                  />
                ))
              }
            </div>
          </div>
          <div className="sidebar__content" id="sidebar__graphs" style={{height: this.state.graphHeight}}>
            <p className="sidebar__title">GRAPHS</p>
            <div className="sidebar__scroll">
              {
                graphs.map((entry, index) => (
                  <div key={index}>
                    <div key={index} className="sidebar__item__hover sidebar__item__hover--margin flex middle-xs pointer" onClick={() => { this.openPip(entry); (!isEdge) && this.expandGraph() }}>
                      <img src={`${images}/Chart@2x.svg`} alt="Chart" className="sidebar__item__folder" />
                      <p className="sidebar__item__name">{entry.name}</p>
                      <UncontrolledDropdown onClick={(e) => e.stopPropagation()}>
                        <DropdownToggle tag="div" className="flex middle-xs" style={{'padding': 10}} onClick={(e) => { e.stopPropagation(); }}>
                          <img src={`${images}/Dots.svg`} alt="Options" className="sidebar__item__dots" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu--noPadding" right>
                          <DropdownItem tag="div">
                            <div className="dropdown__item dropdown__item--margin pointer">Download</div>
                          </DropdownItem>
                          <DropdownItem tag="div">
                            <div className="dropdown__item dropdown__item--margin pointer">Copy to Clipboard</div>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </SplitterLayout>
        {
          this.state.library &&
            <PopoutWindow title={this.state.data.name} onClosing={() => this.setState({library: false})} options={{width: 634, height: 397}}>
              {
                popoutWindow => {
                  copyStyles(window.document, popoutWindow.document);
                  return <LibraryPanel data={this.state.data} />
                }
              }
            </PopoutWindow>
        }
        {
          pip && isEdge &&
            <Rnd className="sidebar__pip" bounds="window" default={{ y: this.state.pip.y, x: this.state.pip.x, width: this.state.pip.width, height: this.state.pip.height }} style={{position: 'fixed'}} minHeight={287} minWidth={300}
              onResize={(e, direction, ref, delta, position) => {
                this.setState(prevState => ({
                  pip: {
                    ...prevState.pip,
                    width: ref.style.width,
                    height: ref.style.height
                  }
                }))
              }}
              enable={{top:true, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:true }}>
              <div className="sidebar__pip__header row middle-xs between-xs">
                <p className="sidebar__pip__name">{pip[0].name}</p>
                <div className="flex middle-xs end-xs">
                  {/* <img src={`${images}/Export@2x.svg`} alt="Export" className="sidebar__pip__export pointer" onClick={() => this.expandGraph(pip[0].graph)} /> */}
                  <img src={`${images}/Close-gray@2x.svg`} className="pointer" alt="Close" onClick={() => this.closeGraph()} />
                </div>
              </div>
              <Graph graphs={pip[0].graph} />
            </Rnd>
        }
        {
          pip && this.state.expand && !isEdge && !isIE &&
            <PopoutWindow title={pip[0].name} onClosing={() => this.setState({expand: false})} options={{width: 634, height: 434}}>
              {
                popoutWindow => {
                  copyStyles(window.document, popoutWindow.document);
                  return <Graph graphs={pip[0].graph} />
                }
              }
            </PopoutWindow>
        }
      </aside>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    tabs: state.app.tabs,
    libraries: state.app.libraries,
    graphs: state.app.graphs,
    pip: state.app.pip,
    output: state.app.console.output
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(HeaderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
import React from 'react';
import Header from './header/Header';
import SessionHeader from './header/SessionHeader';
import Editor from './editor/Editor';
import Sidebar from './sidebar/Sidebar';

if (process.env.NODE_ENV !== 'development') { console.log = () => {} }

const App = () => ( 
	<div className="app-container h100">
  	<Header />
  	<SessionHeader />
  	<div className="row h100">
    	<Editor />
    	<Sidebar />
    </div>
	</div>
)



  // addSession = () => {
  // 	let session = { id: 333, title: 'Python', icon: 'Python@2x.svg' };
  // 	this.setState(prevState => ({ sessions: [...prevState.sessions, session], activeIndex: this.state.sessions.length }));
  // }

export default App;
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import dayjs from 'dayjs';
import CodeMirror from 'codemirror/lib/codemirror';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import { Rnd } from 'react-rnd';
import * as EditorActions from '../../redux/actions/app.actions'
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import AutosizeInput from 'react-input-autosize';
import config from '../../config/config';
import 'codemirror/addon/merge/merge.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/neat.css';
import 'codemirror/mode/python/python.js';
import 'codemirror/mode/sql/sql.js';
import 'codemirror/mode/r/r.js';
import 'codemirror/mode/sas/sas.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/merge/merge';
import './Editor.css';

const images = config.images.URL;

class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openStorage: false,
			compare: false,
			inputValue: '',
			console: {
				width: '100%',
				height: 194
			},
			gitCompare: [],
			select1: '',
			select2: '',
    };
	}

	componentDidMount() {
		axios.get('https://api.github.com/repos/maiconkf/gulp.spritesmith.3x/commits').then(res => {
			let data = res.data;
			let arr = [];

			data.map(item => {
				return arr.push({ author: item.commit.author.name, message: item.commit.message, date: dayjs(item.commit.author.date).format('MMM DD[,] hh[:]mma') })
			});

			this.setState({ gitCompare: arr, select1: arr[0].message, select2: arr[0].message });			
		});
	}

  updateInputValue = (event, selected) => {
		let val = event.target.value;
		console.log(val)
		this.setState({inputValue: val});
		if(val.length > 0) {
			this.props.searchConsole(val)
		} else {
			this.props.showConsole(this.props.consoleSelected)
		}
	}

	handleConsole() {
		let sel = document.getElementsByClassName('logs__menu__item--selected');

		this.setState(prevState => ({
			console: {
				...this.state.console,
				height: 194
			}
		}));
		
		sel[0].style.opacity = 1;
		sel[0].style.fontWeight = 600;
	}

	select1(val) {
		this.setState({select1: val})
	}

	select2(val) {
		this.setState({select2: val})
	}

  render() {
		let { activeFile, openStorage, tabs, type, compareVersion, contentEditor, showConsole, consoleSelected, search, toggleStorage, saveSession } = this.props;
		let inputValue = this.state.inputValue;
		let tabContent = '';
		let contentSaved = '';
		let gitCompare = this.state.gitCompare;

		if(activeFile > -1) {
    	tabContent = tabs[activeFile].content;
			contentSaved = tabs[activeFile].contentSaved;
			
			if(!tabContent) tabContent = '';
			if(!contentSaved) contentSaved = '';
		}

		if(compareVersion) {
			// let merge = document.querySelector('.CodeMirror-merge');
			// if(merge) merge.remove();

			setTimeout(() => {
				CodeMirror.MergeView(this._ref, {
					value: tabContent,
					origLeft: null,
					origRight: contentSaved,
					allowEditingOriginals: false,
					lineNumbers: true,
					mode: type.toLowerCase(),
					highlightDifferences: true,
					theme: 'material',
					connect: 'align'
				});
			});
		}

  	return (
			<div className="row h100 col-xs-9 container-codemirror">
				{
					compareVersion ?
						<>
							<div className="codemirror__header row">
								<div className="codemirror__header__item row middle-xs col-xs-6">
								</div>
								<div className="codemirror__header__item row middle-xs col-xs-6">
									<UncontrolledDropdown>
										<DropdownToggle tag="div">
											<div className="codemirror__select pointer flex middle-xs between-xs">
												<span>{this.state.select2} · v0.1.0 · {gitCompare[0].date}</span>
												<img src={`${images}/Dropdown-gray.svg`} alt="Dropdown" className="codemirror__icon" />
											</div>         
										</DropdownToggle>
										<DropdownMenu className="dropdown-menu--noPadding dropdown-menu--merge start-sm">
											<DropdownItem tag="div" className="codemirror__storage pointer row middle-xs center-xs" onClick={() => toggleStorage(!openStorage)}>
												<img src={`${images}/Upload@2x.svg`} alt="Select from storage" className="modal__icon__upload" />
												<p>Select from storage</p>
											</DropdownItem>
											{
												gitCompare.map((entry, index) => (
													<DropdownItem key={index} tag="div" className="codemirror__menu__item pointer row" onClick={() => this.select2(entry.message)}>
														<p className="codemirror__menu__title">{entry.author} · v0.1.0 · {entry.date}</p>
														<p className="codemirror__menu__desc">{entry.message}</p>
													</DropdownItem>											
												))
											}
										</DropdownMenu>
									</UncontrolledDropdown>
								</div>
							</div>
							<div ref={ref=>this._ref=ref} className="merge h100 row"></div>
						</>
					:
						<ReactCodeMirror
							value={tabContent}
							options={{
								mode: type,
								theme: 'material',
								lineNumbers: true,     
								extraKeys: {
									'Ctrl-S': () => {
										if(activeFile > -1) saveSession(activeFile);
									}
								} 
							}}
							onBeforeChange={(editor, data, value) => {
								contentEditor(activeFile, value);
							}}
						/>
				}
					
				<Rnd className="logs" disableDragging={true} bounds=".container-codemirror" scale={0} size={{ width: this.state.console.width, height: this.state.console.height}} minHeight={63}
					onResize={(e, direction, ref, delta, position) => {
						let h = ref.style.height;
						let sel = document.getElementsByClassName('logs__menu__item--selected');
						this.setState(prevState => ({
							console: {
								width: ref.style.width,
								height: h
							}
						}))

						if(parseInt(h) < 70) {
							sel[0].style.opacity = .24;
							sel[0].style.fontWeight = 500;
						} else {
							sel[0].style.opacity = 1;
							sel[0].style.fontWeight = 600;
						}
					}}
					enable={{top:true, right:false, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}>
					<div className="logs__expand"></div>
					<div className="logs__container row middle-xs">
						<div className="logs__menu col-xs-6 row middle-xs">
							<div className={`logs__menu__item pointer ${consoleSelected === 'Logs' && 'logs__menu__item--selected'}`} style={{ opacity: consoleSelected === 'Logs' ? 1 : .24, fontWeight: consoleSelected === 'Logs' ? 600 : 500 }} onClick={() => { showConsole('Logs'); this.handleConsole(); }}>Logs</div>
							<div className={`logs__menu__item pointer ${consoleSelected === 'Output' && 'logs__menu__item--selected'}`} style={{ opacity: consoleSelected === 'Output' ? 1 : .24, fontWeight: consoleSelected === 'Output' ? 600 : 500 }} onClick={() => { showConsole('Output'); this.handleConsole(); }}>Output</div>
							<div className={`logs__menu__item pointer ${consoleSelected === 'Interactive' && 'logs__menu__item--selected'}`} style={{ opacity: consoleSelected === 'Interactive' ? 1 : .24, fontWeight: consoleSelected === 'Interactive' ? 600 : 500 }} onClick={() => { showConsole('Interactive'); this.handleConsole(); }}>Interactive</div>
							<div className={`logs__menu__item pointer ${consoleSelected === 'Variables' && 'logs__menu__item--selected'}`} style={{ opacity: consoleSelected === 'Variables' ? 1 : .24, fontWeight: consoleSelected === 'Variables' ? 600 : 500 }} onClick={() => { showConsole('Variables'); this.handleConsole(); }}>Variables</div>
						</div>
						<div className="logs__menu--right col-xs-6 row middle-xs end-xs">
							<div className={`logs__menu__item logs__menu__search pointer flex middle-xs ${inputValue !== '' && 'logs__menu__search--active'}`}>
								<img src={`${images}/Search@2x.svg`} alt="Search" className="logs__menu__icon" />
								<AutosizeInput className="logs__search" value={inputValue} placeholder="Search"
									onChange={(e) => {
										this.updateInputValue(e, consoleSelected);
									}}
								/>
							</div>
							{
								(consoleSelected === 'Logs' || consoleSelected === 'Output') &&
									<UncontrolledDropdown>
										<DropdownToggle tag="div" className="logs__menu__item logs__menu__save pointer" style={{'padding': 10}} onClick={(e) => { e.stopPropagation(); }}>
											<img src={`${images}/Download@2x.svg`} alt="Download" className="logs__menu__icon" />
											Save Logs
										</DropdownToggle>
										<DropdownMenu className="dropdown-menu--noPadding dropdown-menu--save">
											<DropdownItem tag="div">
												<div className="dropdown__item dropdown__item--margin pointer">Save</div>
											</DropdownItem>
											<DropdownItem tag="div">
												<div className="dropdown__item dropdown__item--margin pointer">Download</div>
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
							}
							
						</div>
					</div>
					<div className="logs__content">
						{
							search.map((entry, index) => (
								<p key={index}>{entry}</p>
							))
						}
					</div>
				</Rnd>
			</div>
		)
  }
}

const mapStateToProps = (state) => {
	let header = state.app;
	let consoleLog = header.console;

  return {
		activeFile: header.activeFile,
		openStorage: header.openStorage,
    type: header.sessionType,
    tabs: header.tabs,
    consoleSelected: consoleLog.selected,
		search: consoleLog.search,
		compareVersion: header.compareVersion
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(EditorActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
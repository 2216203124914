export const saveSession = (id) => (dispatch) => {
    dispatch(setSavedSuccess(false));
    dispatch(savedError(null));

    callSaveApi(message => {
      if (message === 'Saved') {
        dispatch(setSavedSuccess(true, id, message));

        setTimeout(() => {
          dispatch(setSavedSuccess(false, id, ''));
        }, 3000);
      } else {
        dispatch(savedError(true, message));
      }
    });
}

export const selectSession = (id, type) => (dispatch) => {
  //ajax
  dispatch(sessionType(id, type));
}

export function closeSession(id) {
  console.log(id)
  return {
    type: 'CLOSE_SESSION',
    id
  }
}

export const removeNotification = (id) => (dispatch) => {
  //ajax
  dispatch(removeAlert(id));
}

export const removeAllNotification = () => (dispatch) => {
  //ajax
  dispatch(clearAllAlerts());
}

export function removeAlert(id) {
  return {
    type: 'REMOVE_ALERT',
    id
  }
}

export function clearAllAlerts() {
  return {
    type: 'CLEAR_ALL_ALERTS'
  }
}

export function setSavedSuccess(isSessionSaved, id, message) {
  console.log('id');
  console.log(id);
  return {
    type: 'SET_SESSION_SAVE_SUCCESS',
    id,
    message,
    isSessionSaved,
  };
}

export function callSaveApi(callback) {
  return callback('Saved');
}

export function savedError(loginError, message) {
  return {
    type: 'SET_SESSION_SAVE_ERROR',
    message,
    loginError
  }
}

export function sessionType(id, sessionType) {
  return {
    type: 'SET_SESSION_TYPE',
    id,
    activeFile: id,
    sessionType
  }
}

export function contentEditor(id, content) {
  return {
    type: 'SET_EDITOR_CONTENT',
    id,
    content
  }
}

// export function openFile(activeFile, file) {
//   console.log(file)
//   return {
//     type: 'SET_FILE_HEADER',
//     activeFile,
//     file
//   }
// }

export function openFile(id, index, file) {
  // if(index > 0) index = index - 1; 
  console.log(file)
  return {
    type: 'SET_FILE_HEADER',
    id,
    activeFile: index,
    sessionType: file.type,
    file: {
      ...file,
      id,
      index
    }
  }
}

export const openGraph = (graph) => (dispatch) => {
  //ajax 
  const data = [];

  for (let i = 0; i < 20; i++) {
    const series = [];
    for (let j = 0; j < 100; j++) {
      series.push({x: j, y: (i / 10 + 1) * Math.sin((Math.PI * (i + j)) / 50)});
    }
    data.push({color: i, key: i, data: series, opacity: 0.8});
  }

  dispatch(showGraph(graph, data))
}

export function showGraph(graph, data) {
  return {
    type: 'SET_PIP',
    graph,
    data
  }
}

export function closePip() {
  return {
    type: 'SET_PIP',
    pip: null
  }
}

// export function expandGraph() {
//   return {
//     type: 'SET_PIP',
//     pip: []
//   }
// }

export function showConsole(consoleType) {
  return {
    type: 'SELECT_CONSOLE',
    consoleType
  }
}

export function searchConsole(search) {
  return {
    type: 'SEARCH_CONSOLE',
    search
  }
}

export function searchLibrary(search) {
  return {
    type: 'SEARCH_LIBRARY',
    search
  }
}

export function runCode(file) {
  return {
    type: 'RUN_CODE',
    file
  }
}

export function openSession(id, sessionType) {
  return {
    type: 'OPEN_SESSION',
    id,
    sessionType
  }
}

export function orderBy(storage_opened) {
  return {
    type: 'SORT_STORAGE',
    storage_opened
  }
}

export function open_folder_storage(storage_opened) {
  return {
    type: 'OPEN_STORAGE',
    storage_opened
  }
}

export function openSaveFile(boolean, storage) {
  return {
    type: 'OPEN_STORAGE_TO_SAVE',
    save: boolean,
    storage
  }
}

export function saveAs(file) {
  console.log(file)
  return {
    type: 'FILE_TO_SAVE',
    file
  }
}

export function saveFile(boolean, storage, file) {
  return {
    type: 'SAVE_FILE',
    save: boolean,
    storage,
    file
  }
}

export function setFileName(file) {
  return {
    type: 'SET_FILE_NAME',
    file
  }
}

export const findContainingObject = (array, itemId, file) => (dispatch) => {
  // let index = 0, result = null;
    
  // while (index < array.length && !result) {
  //   const item = array[index];
      
  //   if (item.id === itemId) {
  //     result = item;
  //   } else if (item.content !== undefined) {
  //     result = findContainingObject(item.content, itemId);
  //   }
    
  //   if (result === null) {
  //     index++;
  //   }
  // }


  let result = array.filter((item) => item.id === itemId);

  console.log(result)
 
  dispatch(saveFile(true, result, file));
}

export function compare(compareVersion) {
  return {
    type: 'SET_COMPARE_VERSION',
    compareVersion
  }
}

export function toggleStorage(boolean) {
  return {
    type: 'TOGGLE_STORAGE',
    openStorage: boolean
  }
}

export function toggleModal(boolean, modalType, id) {
  return {
    type: 'TOGGLE_MODAL',
    openModal: boolean,
    modalType,
    id
  }
}

export function toggleNewFolder(boolean) {
  return {
    type: 'TOGGLE_NEW_FOLDER',
    openModal: boolean
  }
}

export function addNewFolder(file) {
  return {
    type: 'SET_STORAGE_OPENED',
    storage_opened: file
  }
}

export function renameFile(index, value) {
  return {
    type: 'SET_NEW_FOLDER_NAME',
    storage_opened_id: index,
    storage_opened_val: value
  }
}

export function deleteFile(index) {
  return {
    type: 'DELETE_FILE',
    storage_opened_id: index
  }
}


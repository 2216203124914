import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../config/config';
import Modal from '../modal/Modal';
import SessionModal from '../modal/SessionModal';
import Storage from '../storage/Storage';
import UserInfo from '../user/User';
import * as HeaderActions from '../../redux/actions/app.actions';
import './Header.css';

const images = config.images.URL;
let sessionProps = '';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewSession: false,
      openUserInfo: false,
    }
  }

  openSessionModal() {
    this.setState({openNewSession: true})
  }

  closeModal() {
    this.setState({
      openNewSession: false,
    });
  }

  endSession(id, content, contentSaved) {
    if(content !== contentSaved) {
      this.props.toggleModal(!this.props.openModal, 'endSession', id);
    } else {
      this.props.closeSession(this.props.activeFile);
    }
  }

  toggleUserInfo() {
    this.setState({ openUserInfo: !this.state.openUserInfo })
  }

  render() {
    let { activeFile, tabs, selectSession, toggleStorage } = this.props;
    return (
      <header className="header">
        <div className="row h100 middle-xs col-xs-12 between-xs">
          <div className="row h100 middle-xs header__section__container">
            <a href="/about">
              <img src={`${images}/Logo.svg`} className="header__logo" alt="ahub IAS" width="26" height="25" />
            </a>
            <div className="h100 col-xs-11 row">
              {
                tabs.map((session, index) => {
                  let img = <img src={`${images}/${session.type}.svg`} className="header__section__icon" alt={session.name} />;
                  if(session.type === 'sas') img = '';

                  if(tabs.length < 4) {
                    sessionProps = '';
                  } else {
                    let tabSize = 100 / (tabs.length + 2) + '%';
                    sessionProps = {
                      style: {
                        flexBasis: tabSize
                      }
                    }
                  }

                  return (
                    <div key={index}
                      className={`header__section col-xs-3 h100 middle-xs between-xs flex ${index === activeFile ? 'header__section--active' : ''}`}
                      {...sessionProps}
                      title={session.name}
                      onClick={() => selectSession(index, session.type)}>
                      <div className="flex middle-xs col-xs-10">
                        {img}
                        <p>{session.name}</p>
                      </div>
                      <div className="flex middle-xs col-xs-2 header__section__close none" onClick={(e) => { e.stopPropagation(); this.endSession(index, session.content, session.contentSaved) }}>
                        <img src={`${images}/Close@2x.svg`} alt="Close session" />
                      </div>
                    </div>
                  )
                })
              }
              <div className="header__btn middle-xs center-xs flex" onClick={this.openSessionModal.bind(this)}>
                <img src={`${images}/Plus.svg`} alt="Add session" className="header__btn__add" />
                <p>New Session</p>
              </div>
            </div>
          </div>
          <div className="header__infos__container h100 row end-xs">
            <a href="/help" className="header__info middle-xs center-xs header__hover flex">
              <img src={`${images}/Question.svg`} alt="Help" className="header__info__icon" />
              <p>Help</p>
            </a>
            <div className="header__info middle-xs center-xs header__hover flex" onClick={() => { toggleStorage(true); }}>
              <img src={`${images}/Folder.svg`} alt="Storage" className="header__info__icon" />
              <p>Storage</p>
            </div>
            <div className="header__info middle-xs center-xs header__hover flex" onClick={() => { this.toggleUserInfo(); }}>
              <img src={`${images}/Person.svg`} alt="User Info" className="header__info__icon" />
              <p>User Info</p>
            </div>
          </div>
        </div>
        <Modal />
        <SessionModal open={this.state.openNewSession} closeModal={() => this.closeModal()} />
        <Storage />
        <UserInfo open={this.state.openUserInfo} toggleUserInfo={() => this.toggleUserInfo()} />
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeFile: state.app.activeFile,
    openModal: state.app.openModal,
    tabs: state.app.tabs
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(HeaderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HeaderActions from '../../redux/actions/app.actions';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import config from '../../config/config';
import './SessionModal.css';

const images = config.images.URL;

const Modal = ({ python, eql, r, sas, tabs, open, closeModal, openFile, openStorage, toggleStorage }) => (
  <div className="modal modal--session h100" style={{display: open ? 'flex' : 'none' }}>
    <div className="modal__fade h100" onClick={closeModal}></div>
    <div className="modal__box modal--session__box flex">
      <div className="modal--session__box__left col-xs-8">
        <img src={`${images}/Close@2x.svg`} alt="Close modal" className="modal__close pointer" width="9" height="9" onClick={closeModal} />
        <div className="modal--session__box__content">
          <img src={`${images}/Logo.svg`} className="modal__logo" alt="ahub IAS" width="34" height="34" />
          <p className="modal__title">IAS Hub</p>
          <p className="modal__desc">Clear explainer goes here in this space</p>
          <div className="col-xs-12 row">
            <div className="col-xs-6">
              <p className="modal__title--gray">START NEW SESSION</p>
              <UncontrolledDropdown>
                <DropdownToggle className="modal__btn--session flex middle-xs between-xs pointer" tag="div">
                  Python
                  <img src={`${images}/python-orange.svg`} alt="Python" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal">
                  <UncontrolledDropdown>
                    <DropdownToggle className="dropdown__item dropdown__item--margin pointer" tag="div">
                      <div className="pointer">Start with a template</div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal dropdown__item--templates">
                      <DropdownItem className="dropdown__item dropdown__item--margin dropdown__title middle-xs pointer" toggle={true} tag="div">
                        <img src={`${images}/Arrow@2x.svg`} alt="Back" className="dropdown__arrow" />
                        TEMPLATES
                      </DropdownItem>
                      {
                        python.map((entry, index) => (
                          <DropdownItem key={index} className="dropdown__item dropdown__item--margin pointer" toggle={true} tag="div" onClick={() => { openFile(entry.id, tabs.length, entry); closeModal(); }}>
                            {entry.name}
                          </DropdownItem>
                        ))
                      }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <DropdownItem className="dropdown__item dropdown__item--margin" toggle={true} tag="div" onClick={() => { openFile(Math.random().toString(36).substr(2, 9), tabs.length, {id: tabs.length, index: '',name: 'Untitled', type: 'python', version: '0.1.0', contentSaved: '', content_type: 'Doc'}); closeModal(); }}>
                    <div className="pointer">Start from scratch</div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle className="modal__btn--session flex middle-xs between-xs pointer" tag="div">
                  EQL
                  <img src={`${images}/eql-orange.svg`} alt="EQL" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal">
                  <UncontrolledDropdown>
                    <DropdownToggle className="dropdown__item dropdown__item--margin pointer" tag="div">
                      <div className="pointer">Start with a template</div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal dropdown__item--templates">
                      <DropdownItem className="dropdown__item dropdown__item--margin dropdown__title middle-xs pointer" toggle={true} tag="div">
                        <img src={`${images}/Arrow@2x.svg`} alt="Back" className="dropdown__arrow" />
                        TEMPLATES
                      </DropdownItem>
                      {
                        eql.map((entry, index) => (
                          <DropdownItem key={index} className="dropdown__item dropdown__item--margin pointer" toggle={true} tag="div" onClick={() => { openFile(entry.id, tabs.length, entry); closeModal(); }}>
                            {entry.name}
                          </DropdownItem>
                        ))
                      }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <DropdownItem className="dropdown__item dropdown__item--margin" toggle={true} tag="div" onClick={() => { openFile(Math.random().toString(36).substr(2, 9), tabs.length, {name: 'Untitled', type: 'eql', version: '0.1.0', contentSaved: '', content_type: 'Doc'}); closeModal(); }}>
                    <div className="pointer">Start from scratch</div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle className="modal__btn--session flex middle-xs between-xs pointer" tag="div">
                  R
                  <img src={`${images}/r-orange.svg`} alt="R" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal">
                  <UncontrolledDropdown>
                    <DropdownToggle className="dropdown__item dropdown__item--margin pointer" tag="div">
                      <div className="pointer">Start with a template</div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal dropdown__item--templates">
                      <DropdownItem className="dropdown__item dropdown__item--margin dropdown__title middle-xs pointer" toggle={true} tag="div">
                        <img src={`${images}/Arrow@2x.svg`} alt="Back" className="dropdown__arrow" />
                        TEMPLATES
                      </DropdownItem>
                      {
                        r.map((entry, index) => (
                          <DropdownItem key={index} className="dropdown__item dropdown__item--margin pointer" toggle={true} tag="div" onClick={() => { openFile(entry.id, tabs.length, entry); closeModal(); }}>
                            {entry.name}
                          </DropdownItem>
                        ))
                      }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <DropdownItem className="dropdown__item dropdown__item--margin" toggle={true} tag="div" onClick={() => { openFile(Math.random().toString(36).substr(2, 9), tabs.length, {name: 'Untitled', type: 'r', version: '0.1.0', content: '', contentSaved: '', content_type: 'Doc'}); closeModal(); }}>
                    <div className="pointer">Start from scratch</div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle className="modal__btn--session flex middle-xs between-xs pointer" tag="div">
                  SAS
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal">
                  <UncontrolledDropdown>
                    <DropdownToggle className="dropdown__item dropdown__item--margin pointer" tag="div">
                      <div className="pointer">Start with a template</div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu--noPadding dropdown__item--modal dropdown__item--templates">
                      <DropdownItem className="dropdown__item dropdown__item--margin dropdown__title middle-xs pointer" toggle={true} tag="div">
                        <img src={`${images}/Arrow@2x.svg`} alt="Back" className="dropdown__arrow" />
                        TEMPLATES
                      </DropdownItem>
                      {
                        sas.map((entry, index) => (
                          <DropdownItem key={index} className="dropdown__item dropdown__item--margin pointer" toggle={true} tag="div" onClick={() => { openFile(entry.id, tabs.length, entry); closeModal(); }}>
                            {entry.name}
                          </DropdownItem>
                        ))
                      }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <DropdownItem className="dropdown__item dropdown__item--margin" toggle={true} tag="div" onClick={() => { openFile(Math.random().toString(36).substr(2, 9), tabs.length, {name: 'Untitled', type: 'sas', version: '0.1.0', content: '', contentSaved: '', content_type: 'Doc'}); closeModal(); }}>
                    <div className="pointer">Start from scratch</div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="col-xs-6">
              <p className="modal__title--gray">RESOURCES</p>
              <a href="/" target="_blank" className="modal__link flex middle-xs">
                <img src={`${images}/Play@2x.svg`} alt="Getting Started" />
                Geting Started
              </a>
              <a href="/" target="_blank" className="modal__link flex middle-xs">
                <img src={`${images}/Book@2x.svg`} alt="Knowledge Base" />
                Knowledge Base
              </a>
              <a href="/" target="_blank" className="modal__link flex middle-xs">
                <img src={`${images}/Question.svg`} alt="Support" />
                Support
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="modal--session__box__right col-xs-4 row">
        <div className="col-xs-12">
          <p className="modal__title--gray modal__title--right">MOST RECENTLY USED</p>
          <div className="modal__used flex middle-xs pointer">
            <img src={`${images}/Python.svg`} alt="Python" />
            <p className="modal__used__name">Phyton :1.0.293:</p>
          </div>
        </div>
        <div className="modal__select row middle-xs center-xs">
          <div className="header__btn pointer row middle-xs center-xs" onClick={() => { toggleStorage(!openStorage); closeModal(); }}>
            <img src={`${images}/Upload@2x.svg`} alt="Select from storage" className="modal__icon__upload" />
            <p>Select from storage</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = (state) => {
  let templates = state.app.templates;

  return {
    tabs: state.app.tabs,
    python: templates[0].python,
    eql: templates[0].eql,
    r: templates[0].r,
    sas: templates[0].sas
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(HeaderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
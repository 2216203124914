import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HeaderActions from '../../redux/actions/app.actions';
import config from '../../config/config';
import './Modal.css';

const images = config.images.URL;

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newFolder: 'Untitled Folder'
    }
  }

  renderSwitch = (props) => {
    switch(props.modalType) {
      case 'endSession':
        return (
          <div className="modal modal--session h100" style={{display: props.openModal ? 'flex' : 'none' }}>
            <div className="modal__fade h100" onClick={() => props.toggleModal(false)}></div>
            <div className="modal__box modal__box--endSession col-xs-8">
              <div className="flex end-xs">
                <img src={`${images}/Close-gray@2x.svg`} alt="Close modal" className="modal__close modal__close--gray pointer" width="9" height="9" onClick={() => props.toggleModal(false)} />
              </div>
              <div className="modal__box__content row">
                <p className="modal__box__title">End Session</p>
                <p className="modal__box__desc">Are you sure you want to end the EQL session? There are unsaved code changes.</p>
                <div className="modal__box__btns row middle-xs between-xs col-xs-12">
                  <div className="btn btn--red pointer col-xs-6 flex center-xs" onClick={() => { props.closeSession(props.modalId); props.toggleModal(false); }}>Discard Changes</div>
                  <div className="btn btn--white pointer col-xs-6 flex center-xs" onClick={() => props.toggleModal(false)}>Cancel</div>
                </div>
              </div>
            </div>
          </div>
        )
      
      // case 'newFolder':
      //   return (
      //     <div className="modal modal--folder h100" style={{display: props.openModal ? 'flex' : 'none' }} onClick={(e) => e.stopPropagation()}>
      //       <div className="modal__fade h100" onClick={() => props.toggleModal(false)}></div>
      //       <div className="modal__box modal__box--folder col-xs-8">
      //         <div className="flex end-xs">
      //           <img src={`${images}/Close-gray@2x.svg`} alt="Close modal" className="modal__close modal__close--gray pointer" width="9" height="9" onClick={() => props.toggleModal(false)} />
      //         </div>
      //         <div className="modal__box__content row">
      //           <p className="modal__box__title modal__box__title--gray">New Folder</p>
      //           <form className="row" onSubmit={(e) => {e.preventDefault(); this.addNewFolder(e)}}>
      //             <input type="text" defaultValue="Untitled folder" className="modal__box__input" onChange={this.handleNewFolder} required />
      //             <div className="modal__box__btns row middle-xs between-xs col-xs-12">
      //               <input type="submit" value="Create" className="btn pointer col-xs-6 flex center-xs" />
      //               <div className="btn btn--white pointer col-xs-6 flex center-xs" onClick={() => props.toggleModal(false)}>Cancel</div>
      //             </div>
      //           </form>
      //         </div>
      //       </div>
      //     </div>
      //   )
      
      case 'saveFile':
        return (
          <div className="modal modal--folder h100" style={{display: props.openModal ? 'flex' : 'none' }} onClick={(e) => e.stopPropagation()}>
            <div className="modal__fade h100" onClick={props.toggleModal}></div>
            <div className="modal__box modal__box--folder col-xs-8">
              <div className="flex end-xs">
                <img src={`${images}/Close-gray@2x.svg`} alt="Close modal" className="modal__close modal__close--gray pointer" width="9" height="9" onClick={props.toggleModal} />
              </div>
              <div className="modal__box__content row">
                <p className="modal__box__title modal__box__title--gray">Name File</p>
                <form className="row" onSubmit={(e) => {e.preventDefault(); props.toggleModal(false); props.toggleStorage(!props.openStorage); props.openSaveFile(true, props.storage); props.saveAs(props.tabs[props.activeFile]); props.setFileName(this.refs.modal__box__input.value)}}>
                  <input type="text" placeholder={`Untitled`} defaultValue={props.tabs[props.activeFile].name} id="modal__box__input" ref="modal__box__input" onClick={() => document.getElementById('modal__box__input').select()} required />
                  <div className="modal__box__btns row middle-xs between-xs col-xs-12">
                    <input type="submit" value="Continue" className="btn pointer col-xs-6 flex center-xs" />
                    <div className="btn btn--white pointer col-xs-6 flex center-xs" onClick={props.toggleModal}>Cancel</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )

        case 'deleteFile':
          return (
            <div className="modal modal--session h100" style={{display: props.openModal ? 'flex' : 'none' }}>
              <div className="modal__fade h100" onClick={() => props.toggleModal(false)}></div>
              <div className="modal__box modal__box--endSession col-xs-8">
                <div className="flex end-xs">
                  <img src={`${images}/Close-gray@2x.svg`} alt="Close modal" className="modal__close modal__close--gray pointer" width="9" height="9" onClick={() => props.toggleModal(false)} />
                </div>
                <div className="modal__box__content row">
                  <p className="modal__box__title col-xs-12">Delete</p>
                  <p className="modal__box__desc">Are you sure you want to delete?</p>
                  <div className="modal__box__btns row middle-xs between-xs col-xs-12">
                    <div className="btn btn--red pointer col-xs-6 flex center-xs" onClick={() => { props.deleteFile(props.modalId); props.toggleModal(false) }}>Delete</div>
                    <div className="btn btn--white pointer col-xs-6 flex center-xs" onClick={() => props.toggleModal(false)}>Cancel</div>
                  </div>
                </div>
              </div>
            </div>
          )
  
      default:
        return;
    }
  }
  
  render() {
    let { activeFile, tabs, storage, modalType, openModal, openStorage, closeSession, newFolder, handleNewFolder, addNewFolder, toggleModal, modalId, deleteFile, toggleStorage, openSaveFile, saveAs, setFileName } = this.props;

    return (
      <>{this.renderSwitch({activeFile, tabs, storage, modalType, openModal, openStorage, closeSession, newFolder, handleNewFolder, addNewFolder, toggleModal, modalId, deleteFile, toggleStorage, openSaveFile, saveAs, setFileName})}</>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    tabs: state.app.tabs, 
    activeFile: state.app.activeFile,
    storage: state.app.storage,
    openModal: state.app.openModal,
    modalType: state.app.modalType,
    modalId: state.app.modalId,
    fileName: state.app.fileName
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(HeaderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
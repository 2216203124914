import React, {Component} from 'react';
import ReactTable from "react-table";
import Pagination from "./Pagination";
import matchSorter from 'match-sorter';
import "react-table/react-table.css";
import './LibraryPanel.css';
import config from '../../config/config';

const images = config.images.URL;

class LibraryPanel extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
      filtered: [],
      filterAll: ''
		}
	}

  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: 'all', value: filterAll }];
    this.setState({ filterAll, filtered });
  }

	render() {
		let {data} = this.props;

	  const columns = [{
	    Header: 'Seq',
	    accessor: 'seq'
	  }, {
	    Header: 'Output name',
	    accessor: 'output_name',
      filterMethod: (filter, row) => {
        return row[filter.id].includes(filter.value);
      }
	  }, {
	    Header: 'Output title',
	    accessor: 'output_title',
	  }, {
	    Header: 'Type',
	    accessor: 'type',
	  }, {
	    Header: 'File name',
	    accessor: 'file',
	  },
    {
      Header: "All",
      id: 'all',
      width: 0,
      resizable: false,
      sortable: false,
      Filter: () => { },
      filterMethod: (filter, rows) => {
        const result = matchSorter(rows, filter.value, {
          keys: [
            "seq",
            "output_name",
            "output_title",
            "type",
            "file"
          ], threshold: matchSorter.rankings.WORD_STARTS_WITH
        });
        return result;
      },
      filterAll: true,
    }];

	  const dataTable = [
	    {
	      seq: '001',
	      output_name: 'Mag_293',
	      output_title: 'Manage 1',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '002',
	      output_name: 'Mag_293',
	      output_title: 'Manage 2',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '003',
	      output_name: 'eMag_293',
	      output_title: 'Manage 3',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '004',
	      output_name: 'Mag_293',
	      output_title: 'Manage 4',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '005',
	      output_name: 'Mag_293',
	      output_title: 'Manage 5',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '006',
	      output_name: 'Mag_293',
	      output_title: 'Manage 6',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '007',
	      output_name: 'Mag_293',
	      output_title: 'Manage 7',
	      type: 'B',
	      file: data.name,
	      none: '',
	    },
	    {
	      seq: '008',
	      output_name: 'Mag_293',
	      output_title: 'Manage 8',
	      type: 'B',
	      file: data.name,
	      none: '',
	    }
	  ];
	  return (
	  	<>
	  		<div className="Table__header row middle-xs between-xs">
	  			<p className="Table__name">{data.name}</p>
	  			<div className="Table__buttons flex middle-xs end-xs">
	  				{/*<div className="Table__btn flex middle-xs center-xs pointer">
		  				<img src={`${window.location.origin}${images}/Search-gray@2x.svg`} alt="Search" />
	            <AutosizeInput className="Table__search" value={this.state.inputValue} placeholder="Search"
	            	onChange={(e) => {
	            		this.setState({inputValue: e});
	            	}}
	            />
	          </div> */}
	  				<div className="Table__btn flex middle-xs center-xs">
	  				  <img src={`${window.location.origin}${images}/Search-gray@2x.svg`} alt="Search" width="10" height="10" />
	  				  <input value={this.state.filterAll} onChange={this.filterAll.bind(this)} placeholder="Search" className="Table__filter pointer" />
	  				</div>
	  				<div className="Table__btn flex middle-xs center-xs pointer">
	  				  <img src={`${window.location.origin}${images}/Download-gray@2x.svg`} alt="Download" />
	  					Download XLS
	  				</div>
	  			</div>
	  		</div>
		    <ReactTable
		      PaginationComponent={Pagination}
		      data={dataTable}
		      columns={columns}
		      filtered={this.state.filtered}
		      defaultPageSize={8}
		      className="-striped -highlight"
		    />
		  </>
	  )
	}
}

export default LibraryPanel;